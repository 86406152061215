<template>
  <div class="row">
    <loading-notification :show="loading" />
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('parameters.view')">
        <actions
          slot="actions"
          crud-links="parameters"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <text-list :title="$t('tables.headings.name')">
          {{ $t(parameter.name) }}
        </text-list>
        <text-list :title="$t('tables.headings.value')">
          {{ parameter.value }}
        </text-list>
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'parameters-view',
  components: {
    Actions, TextList,
  },
  data () {
    return {
      error: false,
      parameter: {},
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `parameters/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const parameterId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(parameterId))
      } catch (err) {
        // console.log('Error fetching parameter data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.parameter = u.data.data
    },
  },
}
</script>
